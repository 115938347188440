import two1 from 'assets/dist/img/2fa-app1.png';
import two2 from 'assets/dist/img/2fa-app2.png';
import { useSelector } from 'react-redux';
import { getTwoFaData } from 'redux/reducers/twoFa/selectors';
import useInput from 'hooks/useInput';
import IconSvg from 'ui/Svg/IconSvg';
import * as yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { FC } from 'react';
import Logo from 'assets/img/top_logo.svg';
import loginBannerImage from '../../assets/dist/img/login-banner@2x-min.png';
import InputTotp from '../../ui/Formik/InputTotp';

interface ITwoFaSettings {
	// eslint-disable-next-line react/require-default-props
	setTotp?: (value: string) => void;
}

const TwoFaSettings: FC<ITwoFaSettings> = ({ setTotp }) => {
	const twoFa = useSelector(getTwoFaData);
	const code = useInput('');

	const initialValues = {
		totp: '',
	};
	const validationSchema = yup.object().shape({
		totp: yup
			.string()
			.required('Please enter 2FA code.')
			.matches(/\d/, String('Only numbers.'))
			.length(6, 'Please enter a 6 digit 2FA code.'),
	});

	const submit = (totp: string) => {
		if (setTotp) {
			setTotp(totp);
		}
	};

	return (
		<>
			<section className="login-section">
				<a href="/" className="login-section__logo">
					<img src={Logo} alt="" />
				</a>
				<div className="container">
					<div className="login login__2fa">
						<Formik
							initialValues={initialValues}
							validationSchema={validationSchema}
							onSubmit={(values: { totp: string }, { setSubmitting, resetForm }) => {
								submit(values.totp);
								setSubmitting(false);
								// resetForm();
							}}
							validateOnBlur
							enableReinitialize
						>
							{({ isSubmitting, isValid, dirty }) => (
								<Form className="login-form login-form--w-660">
									<div className="login-form__title text-left">
										<p className="text-left">Install Google Authenticator</p>
									</div>

									<div className="settings-2fa settings-2fa--reg-setup">
										<div className="settings-2fa__title">
											<div className="settings-2fa__title-number">
												<p>1</p>
											</div>
											<p>Download 2FA App</p>
										</div>
										<div className="settings-2fa__text">
											<p>
												Please download and install an authentication application (we recommend
												Google Authenticator or Authy) on your mobile device. This app will generate
												a one-time 6-digit access code which is required to access your account.
											</p>
										</div>
										<div className="settings-2fa-app flex flex-m">
											<a
												href="https://apps.apple.com/ru/app/google-authenticator/id388497605"
												target="_blank"
												className="settings-2fa-app__item"
												rel="noreferrer"
											>
												<img src={two1} alt="" />
											</a>
											<a
												href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
												target="_blank"
												className="settings-2fa-app__item"
												rel="noreferrer"
											>
												<img src={two2} alt="" />
											</a>
										</div>

										<div className="settings-2fa__divider" />

										<div className="settings-2fa__title">
											<div className="settings-2fa__title-number">
												<p>2</p>
											</div>
											<p>Save 2FA Backup Key and Scan QR Code</p>
										</div>
										<div className="settings-2fa__text">
											<p>
												Please backup your 2FA recovery key by saving to offline storage, writing
												down on a piece of paper or print a copy and store it safety. If your mobile
												device gets lost, stolen or erased you will need this key to recover your
												2FA access.
											</p>
										</div>
										<div className="settings-2fa-qr-code">
											<div className="settings-2fa-qr-code__photo">
												<img src={twoFa?.QR_Image} alt="" />
											</div>
											<div className="settings-2fa-qr-code__info">
												<div className="input">
													<div className="input__name">
														<p>Backup / Secret Key</p>
													</div>
													<div className="input-wrapper">
														<input
															className="input-item input-item--main-color2 input-icon--right-icon"
															type="text"
															placeholder="Enter Key"
															readOnly
															value={twoFa?.secret === undefined ? '' : twoFa?.secret}
														/>
														<div className="input-icon input-icon--bigger input-icon--right">
															<IconSvg name="item-eye" w="24" h="24" />
														</div>
													</div>

													<div className="input__notification input__notification--type3">
														<p>Please make sure you have made a backup of your recovery key.</p>
													</div>
												</div>
											</div>
										</div>

										<div className="settings-2fa__divider" />

										<div className="settings-2fa__title">
											<div className="settings-2fa__title-number">
												<p>3</p>
											</div>
											<p>Confirm 2FA Activation</p>
										</div>
										<div className="settings-2fa-item mb-30">
											<div className="input">
												<div className="input__name">
													<p>Authentication Code</p>
												</div>
												<div className="input-wrapper">
													{/* <input
														className="input-item input-item--pr125"
														type="text"
														placeholder="Please enter the 6-digit code"
														{...code}
													/> */}
													<Field
														type="text"
														placeholder="Code"
														name="totp"
														required
														component={InputTotp}
													/>
												</div>
											</div>
										</div>
										<div>
											<button
												disabled={isSubmitting}
												type="submit"
												className="btn btn-primary btn--full"
											>
												Confirm
											</button>
										</div>
									</div>
								</Form>
							)}
						</Formik>
					</div>
				</div>
			</section>
		</>
	);
};

export default TwoFaSettings;
