import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getPermissions } from 'redux/reducers/auth/selectors';
import { EPermissionNames } from 'redux/reducers/auth/types';
import { EPaymentMethodTab } from './types';
import Crypto from './Crypto/Crypto';
import Fiat from './Fiat/Fiat';

const DepositFeeTab = () => {
	const [activeTab, setActiveTab] = useState<EPaymentMethodTab>(EPaymentMethodTab.CRYPTO);
	const permissions = useSelector(getPermissions);
	const chooseTab = () => {
		switch (activeTab) {
			case EPaymentMethodTab.CRYPTO:
				return <Crypto permission={!!permissions?.[EPermissionNames.FEE_MANAGEMENT].editable} />;
			case EPaymentMethodTab.FIAT:
				return <Fiat permission={!!permissions?.[EPermissionNames.FEE_MANAGEMENT].editable} />;
			default:
				return <Crypto permission={!!permissions?.[EPermissionNames.FEE_MANAGEMENT].editable} />;
		}
	};
	const setToCryptoTab = () => {
		setActiveTab(EPaymentMethodTab.CRYPTO);
	};
	const setToFiatTab = () => {
		setActiveTab(EPaymentMethodTab.FIAT);
	};
	return (
		<>
			<div className="tabs-buttons tabs-buttons--default-fees">
				<button
					type="button"
					className={activeTab === EPaymentMethodTab.CRYPTO ? 'active' : ''}
					onClick={setToCryptoTab}
				>
					Crypto
				</button>
				<button
					type="button"
					className={activeTab === EPaymentMethodTab.FIAT ? 'active' : ''}
					onClick={setToFiatTab}
				>
					Fiat
				</button>
			</div>
			{chooseTab()}
		</>
	);
};
export default DepositFeeTab;
