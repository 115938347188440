import React, { FC, useState } from 'react';
import { Formik } from 'formik';
import { handleFeeFixedChange } from 'services/utils/decimalLimits';
import { numberInputFix } from '../../../../../services/utils/numberInputFix';
import { roundingNumber } from '../../../../../services/utils/roundingNumber';
/* eslint-disable @typescript-eslint/no-explicit-any */

const CryptoItem: FC<any> = ({ data, updateHandler, permission }) => {
	const id = data?.id;
	const withdrawPercent = data?.fee.withdraw_fee_percent;
	const withdrawFixed = data?.fee.withdraw_fee_fixed;
	const code = data?.code;
	const [changeableMode, setChangeableMode] = useState<boolean>(false);
	const switchOnChangeableMode = () => {
		setChangeableMode(true);
	};

	const switchOffChangeableMode = () => {
		setChangeableMode(false);
	};

	const initialValues = {
		withdrawPercent: withdrawPercent || '',
		withdrawFixed: withdrawFixed || '',
	};

	return (
		<>
			{changeableMode ? (
				<Formik
					initialValues={initialValues}
					onSubmit={(values, actions) => {
						actions.setSubmitting(false);
						updateHandler({
							asset_id: id,
							data: {
								withdraw_fee_percent: Number(values.withdrawPercent),
								withdraw_fee_fixed: Number(values.withdrawFixed),
							},
						});
						switchOffChangeableMode();
					}}
				>
					{(props) => (
						<form className="tr is-edit" onSubmit={props.handleSubmit}>
							<div className="td">
								<p className="td-hidden-name">Cryptocurrency</p>
								<div className="td__wrap">
									{/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
									<img src={`/img/currencies/${code.toLowerCase()}.svg`} alt={code} />
									<p className="td-name">{code.toUpperCase()}</p>
									<span className="td-more-info">{data?.name}</span>
								</div>
							</div>
							<div className="td">
								<p className="td-hidden-name">Wallet withdrawPercent</p>
								<input
									className="table__input"
									type="number"
									onKeyDown={(event) => numberInputFix(event)}
									name="withdrawPercent"
									onChange={(event) =>
										handleFeeFixedChange(event, props.setFieldValue, 'withdrawPercent', 'percent')
									}
									onWheel={(event) => event.currentTarget.blur()}
									onBlur={props.handleBlur}
									value={props.values.withdrawPercent}
									placeholder="0"
								/>
							</div>
							<div className="td">
								<p className="td-hidden-name">Wallet withdrawFixed</p>
								<input
									onKeyDown={(event) => numberInputFix(event)}
									className="table__input"
									name="withdrawFixed"
									onChange={(event) =>
										handleFeeFixedChange(event, props.setFieldValue, 'withdrawFixed', data?.type)
									}
									onWheel={(event) => event.currentTarget.blur()}
									onBlur={props.handleBlur}
									value={props.values.withdrawFixed}
									type="number"
									placeholder="0"
								/>
							</div>
							<div className="td td--right">
								<p className="td-hidden-name">Action</p>
								<div className="table-buttons flex-e">
									{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
									<button
										type="button"
										onClick={switchOffChangeableMode}
										className="btn--icon btn--cancel"
									/>
									{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
									<button type="submit" className="btn--icon btn--check" />
								</div>
							</div>
						</form>
					)}
				</Formik>
			) : (
				<div className="tr">
					<div className="td">
						<p className="td-hidden-name">Currency</p>
						<div className="td__wrap">
							{/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
							<img src={`/img/currencies/${code.toLowerCase()}.svg`} alt={code} />
							<p className="td-name">{code.toUpperCase()}</p>
							<span className="td-more-info">{data?.name}</span>
						</div>
					</div>
					<div className="td">
						<p className="td-hidden-name">Fee %</p>
						<div className="td__wrap">
							<p>{withdrawPercent}</p>
						</div>
					</div>
					<div className="td">
						<p className="td-hidden-name">Fee Fixed</p>
						<div className="td__wrap">
							<p>{roundingNumber(withdrawFixed, code)}</p>
						</div>
					</div>

					<div className="td td--right">
						<p className="td-hidden-name">Action</p>
						<div className="table-buttons flex-e">
							{permission && (
								// eslint-disable-next-line jsx-a11y/control-has-associated-label
								<button
									type="button"
									onClick={switchOnChangeableMode}
									className="btn--icon btn--edit"
								/>
							)}
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default CryptoItem;
