/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC, useState, useMemo, useLayoutEffect } from 'react';
import { ITradeLimit } from 'services/api/transactions/types';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrencyData } from 'redux/reducers/currency/selectors';
import { numberInputFix } from 'services/utils/numberInputFix';
import { PencilSmallIcon, CheckIcon, CloseIcon } from 'assets/inline-svg';
import { toMaxDecimals } from 'services/utils/numbers';
import { roundingNumber } from 'services/utils/roundingNumber';

export interface IFeeAssetItem {
	asset_id: number;
	limit_min: number;
	limit_max: number;
}

export interface IFeeManagementDepositItem {
	el: ITradeLimit;
	updateHandler: (obj: IFeeAssetItem) => void;
}

const LimitRow: FC<IFeeManagementDepositItem> = ({ el, updateHandler }) => {
	const { id, asset_id, limit_min, limit_max } = el;
	const [limits, setLimits] = useState({ asset_id, limit_min, limit_max });
	const [changeableMode, setChangeableMode] = useState(false);
	const dispatch = useDispatch();
	const [editedMaximum, setEditedMaximum] = useState(limit_max);
	const handleEditMaximum = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEditedMaximum(Number(event.target.value));
	};
	const [editedMinimum, setEditedMinimum] = useState(limit_min);
	const handleEditMinimum = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEditedMinimum(Number(event.target.value));
	};

	const handleChangeableMode = () => {
		setChangeableMode(!changeableMode);
	};

	// useLayoutEffect(() => {
	// 	if (limits) updateHandler(limits.asset_id, limits.limit_min, limits.limit_max);
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [limits]);

	const switchOnChangeableMode = () => {
		setChangeableMode(true);
	};

	const switchOffChangeableMode = () => {
		setChangeableMode(false);
	};

	const initialValues = {
		limit_min: limit_min || null || '',
		limit_max: limit_max || null || '',
	};

	const currencyData = useSelector(getCurrencyData);

	const filterCurrency = useMemo(() => {
		return currencyData?.find((item) => item.id === asset_id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currencyData]);

	return (
		<>
			{changeableMode ? (
				<Formik
					initialValues={initialValues}
					onSubmit={(values, actions) => {
						actions.setSubmitting(false);
						updateHandler({
							asset_id,
							limit_min: Number(values?.limit_min),
							limit_max: Number(values?.limit_max),
						});
						switchOffChangeableMode();
					}}
				>
					{(props) => (
						<form className="tr tr--editable" onSubmit={props.handleSubmit}>
							<div className="td">
								<p className="td-hidden-name">Currency</p>
								<div className="td__wrap">
									<img
										// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
										src={`/img/currencies/${filterCurrency?.code}.svg`}
										alt={filterCurrency?.code}
									/>
									<p className="td-name">{filterCurrency?.code?.toUpperCase() || '-'}</p>
									<span className="td-more-info">{filterCurrency?.name}</span>
								</div>
							</div>
							<div className="td">
								<p className="td-hidden-name">Minimum</p>
								<div className="input">
									<div className="input-wrapper">
										<input
											className="table__input"
											onKeyDown={(evt) => numberInputFix(evt)}
											onChange={props.handleChange}
											onBlur={props.handleBlur}
											value={props?.values?.limit_min}
											onWheel={(event) => event.currentTarget.blur()}
											name="limit_min"
											type="number"
											placeholder="0"
										/>
									</div>
								</div>
							</div>
							<div className="td">
								<p className="td-hidden-name">Maximum</p>
								<div className="input">
									<div className="input-wrapper">
										<input
											className="table__input"
											onKeyDown={(evt) => numberInputFix(evt)}
											onChange={props.handleChange}
											onBlur={props.handleBlur}
											value={props?.values?.limit_max}
											onWheel={(event) => event.currentTarget.blur()}
											name="limit_max"
											type="number"
											placeholder="0"
										/>
									</div>
								</div>
							</div>
							<div className="td td--right">
								<p className="td-hidden-name">Action</p>
								<div className="table-buttons flex-e">
									{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
									<button
										type="button"
										onClick={switchOffChangeableMode}
										className="btn--icon btn--cancel"
									/>
									{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
									<button type="submit" className="btn--icon btn--check" />
								</div>
							</div>
						</form>
					)}
				</Formik>
			) : (
				<div className="tr">
					<div className="td">
						<p className="td-hidden-name">Currency</p>
						<div className="td__wrap">
							{/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
							<img src={`/img/currencies/${filterCurrency?.code}.svg`} alt={filterCurrency?.code} />
							<p className="td-name">{filterCurrency?.code?.toUpperCase() || '-'}</p>
							<span className="td-more-info">{filterCurrency?.name}</span>
						</div>
					</div>
					<div className="td">
						<p className="td-hidden-name">Minimum</p>
						<div className="td__wrap">
							<p>{roundingNumber(limit_min, filterCurrency?.code)}</p>
						</div>
					</div>
					<div className="td">
						<p className="td-hidden-name">Maximum</p>
						<div className="td__wrap">
							<p>{roundingNumber(limit_max, filterCurrency?.code)}</p>
						</div>
					</div>
					<div className="td td--right">
						<p className="td-hidden-name">Action</p>
						<div className="table-buttons flex-e">
							{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
							<button
								type="button"
								onClick={switchOnChangeableMode}
								className="btn--icon btn--edit"
							/>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default LimitRow;
