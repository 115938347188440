import { NavLink } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

const UserResetRequestsTabs = () => {
	return (
		<div className="tabs-buttons tabs-buttons--pending">
			<NavLink to={ROUTES.UserResetRequest.twofa}>2FA</NavLink>
			<NavLink to={ROUTES.UserResetRequest.password}>Password</NavLink>
		</div>
	);
};

export default UserResetRequestsTabs;
