import React, { FC } from 'react';
import Popup from 'reactjs-popup';
import IconSvg from 'ui/Svg/IconSvg';
import { IAdminDeletedProps } from './types';

const AdminDeleted: FC<IAdminDeletedProps> = ({ open, closeModal }) => {
	return (
		<Popup open={open} onClose={closeModal}>
			<div className="popup popup--width-300">
				{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
				<button type="button" className="popup__close-btn" onClick={closeModal} />
				<div className="popup-header popup-header--mb-20">
					<p className="popup-header__title">Successful</p>
				</div>
				<div className="popup-icon popup-icon--type2">
					<IconSvg name="success-popup" />
				</div>
				<div className="popup-text mb-0">
					<p>Admin was deleted successfully.</p>
				</div>
				{/* <div className="popup-footer">
					<div className="popup-submit popup-submit--column">
						<button type="button" className="btn btn-primary btn--full" onClick={closeModal}>
							Ok
						</button>
					</div>
				</div> */}
			</div>
		</Popup>
	);
};

export default AdminDeleted;
