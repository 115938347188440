import React, { FC } from 'react';
import Popup from 'reactjs-popup';
import { IPopUpData } from 'redux/reducers/popUp/types';
import { IPopUp } from '../types';

const WarningPopUp: FC<IPopUp & IPopUpData> = ({
	open,
	closeModal,
	title,
	message,
	confirmHandler,
}) => {
	const onConfirmClick = () => {
		closeModal();
		!!confirmHandler && confirmHandler();
	};
	const onButtonClick = () => {
		confirmHandler ? onConfirmClick() : closeModal();
	};
	return (
		<Popup open={open} closeOnDocumentClick onClose={closeModal}>
			<div className="popup popup--width-480">
				{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
				<button type="button" className="popup__close-btn" onClick={closeModal} />
				<div className="popup-header">
					<p className="popup-header__title">{title || 'Warning'}</p>
				</div>
				<div className="popup-icon popup-icon--type2">
					<svg
						width="81"
						height="80"
						viewBox="0 0 81 80"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<rect x="1" y="0.5" width="79" height="79" rx="39.5" stroke="#EB5757" />
						<rect x="21" y="20" width="40" height="40" rx="20" fill="#EB5757" />
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M36.25 34C36.25 31.3766 38.3766 29.25 41 29.25C43.6234 29.25 45.75 31.3766 45.75 34V34.25H49C49.4142 34.25 49.75 34.5858 49.75 35C49.75 35.4142 49.4142 35.75 49 35.75H33C32.5858 35.75 32.25 35.4142 32.25 35C32.25 34.5858 32.5858 34.25 33 34.25H36.25V34ZM37.75 34.25H44.25V34C44.25 32.2051 42.7949 30.75 41 30.75C39.2051 30.75 37.75 32.2051 37.75 34V34.25ZM33.9291 36.2534C34.3414 36.2142 34.7075 36.5167 34.7466 36.9291C34.8377 37.887 35.0035 39.08 35.2256 40.6761L35.488 42.5611C35.7785 44.6488 35.9116 45.5736 36.1791 46.3017C36.695 47.7058 37.5528 48.6566 38.4752 49.0458C38.9254 49.2358 39.4708 49.25 41 49.25C42.5292 49.25 43.0746 49.2358 43.5248 49.0458C44.4472 48.6566 45.305 47.7058 45.8209 46.3017C46.0884 45.5736 46.2215 44.6488 46.512 42.5611L46.7744 40.6761C46.9965 39.08 47.1623 37.887 47.2534 36.9291C47.2925 36.5167 47.6586 36.2142 48.0709 36.2534C48.4833 36.2925 48.7858 36.6586 48.7466 37.0709C48.6522 38.0652 48.4817 39.2903 48.2625 40.8651L47.981 42.8881C47.7118 44.8229 47.5592 45.9201 47.2289 46.819C46.6143 48.4918 45.5179 49.8328 44.1079 50.4278C43.3423 50.7509 42.4665 50.7506 41.1627 50.75C41.1092 50.75 41.055 50.75 41 50.75C40.945 50.75 40.8908 50.75 40.8373 50.75C39.5335 50.7506 38.6577 50.7509 37.8921 50.4278C36.4821 49.8328 35.3857 48.4918 34.7711 46.819C34.4408 45.9201 34.2882 44.8229 34.019 42.8881L33.7375 40.8651C33.5183 39.2903 33.3478 38.0652 33.2534 37.0709C33.2142 36.6586 33.5167 36.2925 33.9291 36.2534ZM39 37.25C39.4142 37.25 39.75 37.5858 39.75 38V46C39.75 46.4142 39.4142 46.75 39 46.75C38.5858 46.75 38.25 46.4142 38.25 46V38C38.25 37.5858 38.5858 37.25 39 37.25ZM43 37.25C43.4142 37.25 43.75 37.5858 43.75 38V46C43.75 46.4142 43.4142 46.75 43 46.75C42.5858 46.75 42.25 46.4142 42.25 46V38C42.25 37.5858 42.5858 37.25 43 37.25Z"
							fill="white"
						/>
					</svg>
				</div>
				{!!message && (
					<div className="popup-text">
						<p>{message}</p>
					</div>
				)}
				<div className="popup-footer">
					<div className="popup-submit popup-submit--column">
						<button type="button" className="btn btn-primary" onClick={onButtonClick}>
							Delete
						</button>
						<button
							type="button"
							className="btn-link btn--full justify-content-center"
							onClick={closeModal}
						>
							Cancel
						</button>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default WarningPopUp;
