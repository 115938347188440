import React from 'react';
import { useDispatch } from 'react-redux';
import FilterDateSelect from 'ui/Formik/Select/FilterDateSelect';
import { addFilter } from 'redux/reducers/transactionsFilters/reducer';
import { EFiltersType, ETransactionsFilters } from 'redux/reducers/transactionsFilters/types';

const ReferralsTradeTableHeader = () => {
	const dispatch = useDispatch();

	const onChangeDate = (startDate: Date, endDate: Date) => {
		dispatch(
			addFilter({
				type: ETransactionsFilters.DATE,
				value: { date_from: startDate, date_to: endDate },
				filterType: EFiltersType.TRANSACTIONS_TRADES,
			}),
		);
	};
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<div className="td-name">
						{/* <FilterDateSelect title="Timestamp" onChange={onChangeDate} /> */}
						<p>Timestamp</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Account Email</p>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Referral Fee</p>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Referral %</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Sell</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Buy</p>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Sold</p>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Received</p>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Exchange</p>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Fee</p>
					</div>
				</div>
				<div className="td td--center">
					<div className="td-name">
						<p>TxID</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ReferralsTradeTableHeader;
