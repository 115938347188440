import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { FC } from 'react';
import Popup from 'reactjs-popup';
import IconSvg from 'ui/Svg/IconSvg';
import Input from 'ui/Formik/Input';
import { IRejectModalProps } from './types';

// ================================================:
export const RejectModal: FC<IRejectModalProps> = ({
	openModal,
	closeModal,
	handleRejectModal,
}) => {
	const initialValues = {
		note: '',
	};

	const validationSchema = yup.object().shape({
		note: yup
			.string()
			.required('Please, enter message!')
			.max(255, 'Message is too long. Max 255 characters.'),
	});

	return (
		<Popup
			open={openModal}
			closeOnDocumentClick
			onClose={closeModal}
			closeOnEscape
			overlayStyle={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
		>
			<div className="popup">
				{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
				<button type="button" className="popup__close-btn" onClick={closeModal} />
				<Formik
					validateOnBlur
					enableReinitialize
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={(values, { resetForm, setSubmitting }) => {
						setSubmitting(false);
						handleRejectModal(values.note);
						closeModal();
					}}
				>
					<Form>
						<div className="popup-header popup-header--mb-20">
							<h3 className="popup-header__title">Reject 2FA Reset Request</h3>
						</div>
						<div className="popup-icon popup-icon--type2">
							<IconSvg name="warning-popup" />
						</div>
						<div className="popup-body">
							<div className="popup-text popup-text--center">
								<p>Are you sure you want to reject the 2FA reset request?</p>
							</div>
							<Field
								required
								type="textarea"
								placeholder="Text"
								component={Input}
								name="note"
								title="Message"
							/>
							<div className="popup-footer">
								<div className="popup-submit popup-submit--column">
									<button type="submit" className="btn btn-primary btn--full">
										Confirm
									</button>
									<button
										type="button"
										className="btn-link btn--full justify-content-center"
										onClick={closeModal}
									>
										Cancel
									</button>
								</div>
							</div>
						</div>
					</Form>
				</Formik>
			</div>
		</Popup>
	);
};
