import { FC } from 'react';
import { Link } from 'react-router-dom';
import {
	TWO_FA_STATUS_NUM_NEW,
	TWO_FA_STATUS_NUM_REJECT,
	TWO_FA_STATUS_NUM_REJECT_2,
} from 'redux/reducers/users/constants';
import { IAdminPasswordResetRequest } from '../ResetRequestItem/types';
import { ResetRejectButtons } from './ResetRejectButtons';

export interface IResetItemProps {
	data: IAdminPasswordResetRequest;
	permission: boolean;
	handleClearSearch?: () => void;
}

const ResetUserPasswordItem: FC<IResetItemProps> = ({ data, handleClearSearch, permission }) => {
	const showStatus = () => {
		if (data.status === TWO_FA_STATUS_NUM_NEW) {
			return 'New';
		}
		if (data.status === TWO_FA_STATUS_NUM_REJECT || data.status === TWO_FA_STATUS_NUM_REJECT_2) {
			return 'Rejected';
		}
		return 'Accepted';
	};
	const formatedDate = () => {
		if (data.created_at) {
			const dateArr = data?.created_at.slice(0, 10).split('-');
			return `${dateArr[2]}/${dateArr[1]}/${dateArr[0]}`;
		}
		return null;
	};
	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Admin ID</p>
				<div className="td__wrap td__wrap--column td__wrap--column-no-gap">
					<Link className="link" to={`/user-management/${String(data.userable.id)}`}>
						{data?.userable?.id}
					</Link>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">E-mail</p>
				<div className="td__wrap td__wrap--column td__wrap--column-no-gap">
					<Link className="link" to={`/user-management/${String(data.userable.id)}`}>
						{data?.userable.email}
					</Link>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Type</p>
				<div className="td__wrap td__wrap--column td__wrap--column-no-gap">
					<div className="td__text">
						<p>Password</p>
					</div>
				</div>
			</div>

			<div className="td td--status">
				<p className="td-hidden-name">Status</p>
				<div className="td__wrap td__wrap--column td__wrap--column-no-gap">
					<p className={`status status--type2 status--${showStatus().toLowerCase()}`}>
						{showStatus()}
					</p>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Date</p>
				<div className="td__wrap td__wrap--column td__wrap--column-no-gap">
					<p>{formatedDate()}</p>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">IP Address</p>
				<div className="td__wrap td__wrap--column td__wrap--column-no-gap">
					<p>{data?.ip}</p>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Reason</p>
				<div className="td__wrap td__wrap--column td__wrap--column-no-gap">
					<p>{data?.reason}</p>
				</div>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Action</p>
				{permission && (
					<ResetRejectButtons
						id={data.id}
						handleClearSearch={handleClearSearch}
						status={data.status}
					/>
				)}
			</div>
		</div>
	);
};

export default ResetUserPasswordItem;
