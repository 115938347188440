import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import Input from 'ui/Formik/Input';
// import { ReactComponent as EmailIcon } from 'assets/img/admin/login/email.svg';
import PopUp from 'layouts-elements/PopUp/PopUp';
import { resetTwoFaRequest } from 'redux/reducers/auth/reducer';
import { Helmet } from 'react-helmet';
import React from 'react';
import Logo from '../../assets/img/top_logo.svg';

const ResetTwoFaPage = () => {
	const dispatch = useDispatch();

	const initialValues = {
		email: '',
		reason: '',
	};

	const validationSchema = yup.object().shape({
		email: yup
			.string()
			.required('Please enter your email.')
			.email('Please enter a valid email address.')
			.max(60, 'Email Address is too long. Maximum 60 characters.'),
		reason: yup
			.string()
			.required('Please explain reason for 2FA reset.')
			.max(120, 'Reason is too long. Maximum 120 characters.'),
	});

	return (
		<>
			<Helmet>
				<body className="login-page" />
			</Helmet>
			<section className="login-section">
				<a href="/" className="login-section__logo">
					<img src={Logo} alt="" />
				</a>
				<div className="login">
					<div className="login-form">
						<div className="login-form__title">
							<p>Reset 2FA</p>
						</div>
						<Formik
							initialValues={initialValues}
							validationSchema={validationSchema}
							onSubmit={(values, { resetForm, setSubmitting }) => {
								dispatch(resetTwoFaRequest({ ...values, type: 2, captcha: '000000' }));
								setSubmitting(false);
								resetForm();
							}}
							validateOnBlur
							enableReinitialize
						>
							{({ isSubmitting }) => (
								<Form className="form">
									<div className="login-form-item">
										<div className="input">
											<p className="input__name">Email</p>
											<div className="input-wrapper">
												<Field
													type="email"
													placeholder="Email"
													name="email"
													required
													component={Input}
												/>
											</div>
										</div>
									</div>
									<div className="login-form-item mb-30">
										<div className="input">
											<p className="input__name">Reason</p>
											<div className="input-wrapper">
												<Field placeholder="Reason" name="reason" required component={Input} />
											</div>
										</div>
									</div>
									<div className="login-form-item mb-0">
										<button
											type="submit"
											disabled={isSubmitting}
											className="btn btn-primary btn--full"
										>
											Reset 2FA
										</button>
									</div>
								</Form>
							)}
						</Formik>
					</div>
				</div>
			</section>
			<PopUp />
		</>
	);
};

export default ResetTwoFaPage;
