import { useLayoutEffect, FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from 'ui/Pagination/Pagination';
import PerPageSelect from 'ui/PerPageSelect/PerPageSelect';
import { getFeeRequest } from 'redux/reducers/fee/reducer';
import { getFeeStoreData } from 'redux/reducers/fee/selectors';
import { getUserCreateData } from 'redux/reducers/users/selectors';
import { setUserData } from 'redux/reducers/users/reducer';
import FeeManagementDepositItem from './FeeManagementDepositItem/FeeManagementDepositItem';
import { IFeesProps } from './types';

const FeeManagementDeposit: FC<IFeesProps> = ({ activeCoin }) => {
	const dispatch = useDispatch();
	const fee = useSelector(getFeeStoreData);
	const userData = useSelector(getUserCreateData);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState<number | undefined>(10);
	const handleSetPerPage = (value: number | undefined) => {
		setPerPage(value);
		setCurrentPage(1);
	};
	const pageCount = fee?.data?.last_page || 1;

	const assetFilterData = fee?.data?.data?.map((el: any) => {
		const currentAsset = userData?.asset_fees?.find(
			(item: any) => item.asset_id === el.fee.asset_id,
		);
		if (currentAsset) {
			return {
				...el,
				fee: {
					...el.fee,
					deposit_fee_percent: currentAsset?.deposit_fee_percent,
					deposit_fee_fixed: currentAsset.deposit_fee_fixed,
				},
			};
		}
		return el;
	});

	useLayoutEffect(() => {
		const data = {
			current_page: currentPage,
			per_page: perPage,
			type: activeCoin,
		};
		dispatch(getFeeRequest(data));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, activeCoin, perPage, currentPage]);

	const updateHandler = ({ asset_id, deposit_fee_percent, deposit_fee_fixed }: any) => {
		let newData: any = userData?.asset_fees ? [...userData.asset_fees] : [];

		const asset = userData?.asset_fees?.find((item) => item.asset_id === asset_id);
		if (asset && userData?.asset_fees) {
			newData = userData?.asset_fees?.map((item) => {
				return item.asset_id === asset_id
					? { ...item, deposit_fee_percent, deposit_fee_fixed }
					: item;
			});
		} else {
			fee?.data?.data.forEach((item: any) => {
				if (item.fee.asset_id === asset_id) {
					newData.push({
						asset_id: item?.fee.asset_id,
						deposit_fee_percent,
						deposit_fee_fixed,
						withdraw_fee_percent: item?.fee?.withdraw_fee_percent,
						withdraw_fee_fixed: item?.fee?.withdraw_fee_fixed,
						trade_fee_percent: item?.fee?.trade_fee_percent,
						trade_fee_fixed: item?.fee?.trade_fee_fixed,
						trade_slippage: item?.fee?.trade_slippage,
					});
				}
			});
		}

		dispatch(setUserData({ ...userData, asset_fees: newData }));
	};

	return (
		<>
			<div className="table-block">
				<div className="table-wrapper">
					<div
						className={`table ${
							activeCoin === 'crypto'
								? 'table--user-management-fee-management-deposit-crypto'
								: 'table--user-management-fee-management-deposit-fiat'
						}`}
					>
						<div className="table-header">
							<div className="tr">
								<div className="td">
									<div className="td-name">
										<p>Currency</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Fee %</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Fee Fixed</p>
									</div>
								</div>
								<div className="td td--right">
									<div className="td-name" />
								</div>
							</div>
						</div>
						<div className="table-body">
							{assetFilterData?.length
								? assetFilterData?.map((el: any) => (
										<FeeManagementDepositItem key={el.id} updateHandler={updateHandler} el={el} />
								  ))
								: 'Empty'}
						</div>
						{!!assetFilterData.length && (
							<div className="table-footer">
								<div className="pagination-block">
									<PerPageSelect onChange={handleSetPerPage} />
									{pageCount > 1 && (
										<Pagination
											pageCount={pageCount}
											currentPage={currentPage}
											onPageChange={setCurrentPage}
										/>
									)}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			{/* {!!assetFilterData.length && (
				<div className="table-pagination">
					<PerPageSelect onChange={handleSetPerPage} />
					{pageCount > 1 && (
						<div className="pagination-block">
							<Pagination
								pageCount={pageCount}
								currentPage={currentPage}
								onPageChange={setCurrentPage}
							/>
						</div>
					)}
				</div>
			)} */}
		</>
	);
};

export default FeeManagementDeposit;
