/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import IconSvg from 'ui/Svg/IconSvg';
import { setUserData } from 'redux/reducers/users/reducer';
import { getFeeCurrenciesRequest } from 'redux/reducers/fee/reducer';
import { currencyDataRequest } from 'redux/reducers/currency/reducer';
import { getFeeStoreData, updateState } from 'redux/reducers/fee/selectors';
import { getUserCreateData } from 'redux/reducers/users/selectors';
import CurrenclesItem from './CurrenclesItem/CurrenclesItem';

const CurrenclesFee = () => {
	const dispatch = useDispatch();
	const fee = useSelector(getFeeStoreData);
	const userData = useSelector(getUserCreateData);

	const updateStateReqvest = useSelector(updateState);
	const perPage = 9;
	const totalPage = fee.dataCurrencies.total;

	// const [getAsset, setUserAsset] = useState([
	// 	{ asset_id: 1, depositable: true, withdrawable: true, exchangeable: true },
	// ]);

	// useLayoutEffect(() => {
	// 	dispatch(setUserData({ ...userData, assets: getAsset }));
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [dispatch, getAsset]);

	useLayoutEffect(() => {
		const data = {
			per_page: -1,
			current_page: 1,
		};
		dispatch(getFeeCurrenciesRequest(data));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateStateReqvest]);

	useLayoutEffect(() => {
		dispatch(currencyDataRequest());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useLayoutEffect(() => {
		if (fee.dataCurrencies.length === userData?.assets?.length) return;
		const newFee = fee.dataCurrencies?.map((e: any) => {
			return {
				code: e.code,
				asset_id: e?.id,
				depositable: e.depositable,
				withdrawable: e.withdrawable,
				exchangeable: e.exchangeable,
			};
		});
		dispatch(setUserData({ ...userData, assets: newFee }));
		// setUserAsset(newFee);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, fee]);

	const handlePageClick = ({ selected }: { selected: number }) => {
		const data = {
			per_page: 9,
			current_page: selected + 1,
		};
		dispatch(getFeeCurrenciesRequest(data));
	};

	const updateHandler = (id: number, text: string, value: any) => {
		const newAsset = userData?.assets?.map((item) => {
			return item.asset_id === id ? { ...item, [text]: value } : item;
		});
		// setUserAsset(() => [...newAsset]);
		dispatch(setUserData({ ...userData, assets: newAsset }));
	};

	return (
		<>
			<div className="table-block">
				<div className="table-wrapper">
					<div className="table table--fee-management-currencles">
						<div className="table-header">
							<div className="tr">
								<div className="td">
									<div className="td-name">
										<p>Currency</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Deposit</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Withdrawal</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Trade</p>
									</div>
								</div>
							</div>
						</div>
						<div className="table-body">
							{userData?.assets?.map((e: any) => {
								return <CurrenclesItem key={e.asset_id} updateHandler={updateHandler} data={e} />;
							})}
						</div>
						{/* {totalPage > perPage && (
							<div className="table-footer">
								<div className="pagination-block">
									<ReactPaginate
										breakLabel="..."
										pageCount={Math.ceil(totalPage / perPage)}
										onPageChange={handlePageClick}
										className="pagination"
										activeClassName="active"
										previousClassName="arrow"
										nextClassName="arrow"
										previousLabel={<IconSvg name="keyboard-arrow-left" w="10" h="16" />}
										nextLabel={<IconSvg name="keyboard-arrow-right" w="10" h="16" />}
									/>
								</div>
							</div>
						)} */}
					</div>
				</div>
			</div>
		</>
	);
};

export default CurrenclesFee;
