import React, { FC } from 'react';
import Popup from 'reactjs-popup';
import image from 'assets/dist/img/email-icon.svg';
import { useDispatch } from 'react-redux';
import IconSvg from 'ui/Svg/IconSvg';
import { forgotPasswordPopupClose } from 'redux/reducers/auth/reducer';
import { IPropsPopUp } from './types';

const ResetPasswordPopUp: FC<IPropsPopUp> = ({ open, closeModal }) => {
	const dispatch = useDispatch();
	const onClose = () => {
		dispatch(forgotPasswordPopupClose());
		closeModal();
	};
	return (
		<Popup open={open} onClose={onClose} closeOnDocumentClick>
			<div className="popup">
				{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
				<button type="button" className="popup__close-btn" onClick={closeModal} />
				<div className="popup-header popup-header--mb-20">
					<p className="popup-header__title">Request Sent</p>
				</div>
				<div className="popup-icon popup-icon--type2">
					<IconSvg name="success-popup" />
				</div>
				<div className="popup-body">
					<div className="popup-text popup-text--mt popup-text--mb-0 popup-text--center">
						<p>
							Your password reset request is being reviewed. Once approved you will receive a reset
							via email.
						</p>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default ResetPasswordPopUp;
