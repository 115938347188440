import { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from 'ui/Pagination/Pagination';
import PerPageSelect from 'ui/PerPageSelect/PerPageSelect';
import { getFeeSpreadManagementRequest } from 'redux/reducers/fee/reducer';
import { getFeeStoreData } from 'redux/reducers/fee/selectors';
import { getUserCreateData } from 'redux/reducers/users/selectors';
import { setUserData } from 'redux/reducers/users/reducer';
import FeeManagementSpreadItem from './FeeManagementSpreadItem/FeeManagementSpreadItem';

const FeeManagementSpread = () => {
	const dispatch = useDispatch();
	const fee = useSelector(getFeeStoreData);
	const userData = useSelector(getUserCreateData);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState<number | undefined>(10);
	const handleSetPerPage = (value: number | undefined) => {
		setPerPage(value);
		setCurrentPage(1);
	};
	const pageCount = fee?.dataSpreadManagement?.last_page || 1;

	const assetFilterData = fee?.dataSpreadManagement?.data?.map((el: any) => {
		const currentAsset = userData?.spread?.find((item: any) => item.pair_id === el.id);
		if (currentAsset) {
			return {
				...el,
				spread: currentAsset.spread,
			};
		}
		return el;
	});

	useLayoutEffect(() => {
		const data = {
			current_page: currentPage,
			per_page: perPage,
		};
		dispatch(getFeeSpreadManagementRequest(data));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, perPage, currentPage]);

	const updateHandler = ({ pair_id, spread }: any) => {
		let newData: any = userData?.spread ? [...userData.spread] : [];

		const asset = userData?.spread?.find((item) => item.pair_id === pair_id);
		if (asset && userData?.spread) {
			newData = userData?.spread?.map((item) => {
				return item.pair_id === pair_id ? { ...item, spread } : item;
			});
		} else {
			fee?.dataSpreadManagement?.data?.forEach((item: any) => {
				if (item.id === pair_id) {
					newData.push({
						pair_id: item?.id,
						spread,
					});
				}
			});
		}

		dispatch(setUserData({ ...userData, spread: newData }));
	};

	return (
		<>
			<div className="table-block">
				<div className="table-wrapper">
					<div className="table table--user-management-fee-management-spread">
						<div className="table-header">
							<div className="tr">
								<div className="td">
									<div className="td-name">
										<p>Pair</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Spread %</p>
									</div>
								</div>
								<div className="td td--right">
									<div className="td-name" />
								</div>
							</div>
						</div>
						<div className="table-body">
							{assetFilterData?.length
								? assetFilterData?.map((el: any) => (
										<FeeManagementSpreadItem key={el.id} updateHandler={updateHandler} el={el} />
								  ))
								: 'Empty'}
						</div>
						{!!assetFilterData.length && (
							<div className="table-footer">
								<div className="pagination-block">
									<PerPageSelect onChange={handleSetPerPage} />
									{pageCount > 1 && (
										<Pagination
											pageCount={pageCount}
											currentPage={currentPage}
											onPageChange={setCurrentPage}
										/>
									)}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			{/* {!!assetFilterData.length && (
				<div className="table-pagination">
					<PerPageSelect onChange={handleSetPerPage} />
					{pageCount > 1 && (
						<div className="pagination-block">
							<Pagination
								pageCount={pageCount}
								currentPage={currentPage}
								onPageChange={setCurrentPage}
							/>
						</div>
					)}
				</div>
			)} */}
		</>
	);
};

export default FeeManagementSpread;
