// ==========================================:
import React from 'react';
import { ROUTES } from 'routes/constants';
import logo from 'assets/dist/img/logo-footer.svg';
import { useDispatch } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';

const Footer = () => {
	const { pathname } = useLocation();
	const dispatch = useDispatch();

	const handleFooterClassName = () => {
		if (
			pathname === '/login' ||
			pathname === ROUTES.CREATE_NEW_PASSWORD ||
			pathname === ROUTES.CREATE_TWOFA ||
			pathname === ROUTES.firstLogin2fa ||
			pathname === '/reset-password' ||
			pathname === '/reset-2fa' ||
			pathname === '/first_login'
		) {
			return 'footer footer__visible';
		}
		return 'footer';
	};

	return (
		<footer className={handleFooterClassName()}>
			<div className="footer-container">
				{/* <a href="!#" className="logo">
					<img alt="Logo" src={logo} />
				</a> */}
				<div className="footer__support">
					<a href="mailto:support@kaiserex.com">support@kaiserex.com</a>
				</div>
				<div className="footer-nav">
					<ul>
						<li>
							<a href="/terms" target="_blank">
								Service Agreement
							</a>
						</li>
						<li>
							<a href="/privacy" target="_blank">
								Privacy Policy
							</a>
						</li>
						{/* <li>
							<button
								onClick={() => {
									dispatch(popUpOpen('supportSuccess'));
								}}
								type="submit"
								id="footer_support_btn"
							>
								Support
							</button>
						</li> */}
					</ul>
				</div>
			</div>
		</footer>
	);
};
export default Footer;
