/* eslint-disable jsx-a11y/anchor-is-valid */
import { IAccountDetailsParams } from 'components/AccountDetails/types';
import React, { FC, useLayoutEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { EWithdrawalLimitType } from 'services/api/transactions/types';
import Pagination from 'ui/Pagination/Pagination';
import PerPageSelect from 'ui/PerPageSelect/PerPageSelect';
import { useDispatch, useSelector } from 'react-redux';
import { getTradesLimits, getTradesLimitsLoading } from 'redux/reducers/transactions/selectors';
import { IGetTradeLimitPayload } from 'redux/reducers/transactions/types';
import {
	getTradingLimitsCryptoRequest,
	getTradingLimitsFiatRequest,
} from 'redux/reducers/transactions/reducer';
import { setUserData } from 'redux/reducers/users/reducer';
import { getUserCreateData } from 'redux/reducers/users/selectors';
import TableBodyNoData from 'ui/TableBodyNoData/TableBodyNoData';
import { useParams } from 'react-router';

import IconSvg from 'ui/Svg/IconSvg';
import LimitRow from './LimitRow';

export interface ITransfersLimitsTable {
	type: string;
}

const DataTable: FC<ITransfersLimitsTable> = ({ type }) => {
	const dispatch = useDispatch();
	const tradingLimits = useSelector(getTradesLimits);
	const tradingLimitsLoading = useSelector(getTradesLimitsLoading);
	const userData = useSelector(getUserCreateData);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState<number | undefined>(10);
	const pageCount = tradingLimits?.last_page || 1;

	const handleSetPerPage = (value: number | undefined) => {
		setPerPage(value);
		setCurrentPage(1);
	};

	useLayoutEffect(() => {
		const params: IGetTradeLimitPayload = {
			current_page: currentPage,
			per_page: perPage,
		};
		type === 'crypto' && dispatch(getTradingLimitsCryptoRequest(params));
		type === 'fiat' && dispatch(getTradingLimitsFiatRequest(params));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [type]);

	const assetFilterData = tradingLimits?.data?.map((el: any) => {
		const currentAsset = userData?.trading_limits?.find(
			(item: any) => item.asset_id === el.asset_id,
		);
		if (currentAsset) {
			return {
				...el,
				limit_min: currentAsset?.limit_min,
				limit_max: currentAsset.limit_max,
			};
		}
		return el;
	});
	/* eslint-disable */
	const updateHandler = ({ asset_id, limit_min, limit_max }: any) => {
		let newData: any = userData?.trading_limits ? [...userData.trading_limits] : [];
		// debugger;
		const asset = userData?.trading_limits?.find((item) => item.asset_id === asset_id);
		if (asset && userData?.trading_limits) {
			newData = userData?.trading_limits?.map((item) => {
				return item.asset_id === asset_id
					? { ...item, limit_min: limit_min, limit_max: limit_max }
					: item;
			});
		} else {
			tradingLimits?.data?.forEach((item: any) => {
				if (item.asset_id === asset_id) {
					newData.push({
						asset_id: item?.asset_id,
						limit_min: limit_min,
						limit_max: limit_max,
					});
				}
			});
		}

		dispatch(setUserData({ ...userData, trading_limits: newData }));
	};

	return (
		<>
			<div className="table-block">
				<div className="table-wrapper">
					<div className="table table--trading-limits-crypto">
						<div className="table-header">
							<div className="tr">
								<div className="td">
									<div className="td-name">
										<p>Currency</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Limit Min</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Limit Max</p>
									</div>
								</div>
								<div className="td td--right" />
							</div>
						</div>
						<div className="table-body">
							{assetFilterData?.length && !tradingLimitsLoading ? (
								assetFilterData.map((el: any) => (
									<LimitRow key={el.id} el={el} updateHandler={updateHandler} />
								))
							) : (
								<TableBodyNoData />
							)}
						</div>
						{!!tradingLimits?.data.length && (
							<div className="table-footer">
								<div className="pagination-block">
									<PerPageSelect onChange={handleSetPerPage} />
									{pageCount > 1 && (
										<Pagination
											pageCount={pageCount}
											currentPage={currentPage}
											onPageChange={setCurrentPage}
										/>
									)}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>

			{/* {!!tradingLimits?.data.length && (
					<div className="table-pagination">
						<PerPageSelect onChange={handleSetPerPage} />
						{pageCount > 1 && (
							<div className="pagination-block">
								<Pagination
									pageCount={pageCount}
									currentPage={currentPage}
									onPageChange={setCurrentPage}
								/>
							</div>
						)}
					</div>
				)} */}
		</>
	);
};

export default DataTable;

