import { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { currencyDataRequest } from 'redux/reducers/currency/reducer';
import { dashboardCryptoRequest } from 'redux/reducers/dashboard/reducer';
import { getDashboard } from 'redux/reducers/dashboard/selectors';
import StatBlock from './StatBlock/StatBlock';
import { EStatType } from './StatBlock/types';

const CryptoTab = () => {
	const dispatch = useDispatch();
	const cryptoData = useSelector(getDashboard);
	useLayoutEffect(() => {
		dispatch(currencyDataRequest());
		dispatch(dashboardCryptoRequest());
	}, [dispatch]);

	return (
		<>
			<StatBlock title="Total Liquidity" data={cryptoData.liquidity} type={EStatType.LIQUIDITY} />
			<StatBlock
				title="Platform Balances"
				data={cryptoData.platformBalance} // cryptoData.platformBalance
				type={EStatType.PLATFORM_BALANCES}
			/>
			<StatBlock
				title="Deposit Wallets"
				data={cryptoData.depositBalance}
				type={EStatType.DEPOSIT_BALANCES}
			/>
			<StatBlock
				title="Pooled Wallets"
				data={cryptoData.poolBalance}
				type={EStatType.POOL_BALANCES}
			/>
			<StatBlock
				title="Withdrawal Wallets"
				data={cryptoData.withdrawBalance}
				type={EStatType.WITHDRAW_BALANCES}
			/>
			<StatBlock title="Pending Fees" data={cryptoData.pendingFee} type={EStatType.PENDING_FEES} />
			<StatBlock
				title="Profit Wallets"
				data={cryptoData.profitBalance}
				type={EStatType.PROFIT_BALANCES}
			/>
			<StatBlock
				title="B2C2 Balances"
				data={cryptoData.b2c2Balance}
				type={EStatType.B2C2_BALANCES}
			/>
		</>
	);
};

export default CryptoTab;
