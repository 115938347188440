import React, { FC } from 'react';
import Popup from 'reactjs-popup';
import IconSvg from 'ui/Svg/IconSvg';
import { IPropsPopUp } from './types';
import { ROUTES } from '../../../routes/constants';

const TwoFaSetupSuccessPopUp: FC<IPropsPopUp> = ({ open, closeModal }) => {
	const onClose = () => {
		closeModal();
		window.location.replace(ROUTES.login);
	};
	return (
		<Popup open={open} onClose={onClose} closeOnDocumentClick>
			<div className="popup">
				{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
				<button type="button" className="popup__close-btn" onClick={onClose} />
				<div className="popup-icon popup-icon--type2">
					<IconSvg name="success-popup" />
				</div>
				<div className="popup-header popup-header--mb-20">
					<p className="popup-header__title">Setup Successful</p>
				</div>
				<div className="popup-text">
					<p>Thank you. Your Password and 2FA have been setup successfully.</p>
				</div>
				<div className="popup-footer">
					<div className="popup-submit">
						<button type="button" className="btn btn-primary btn--full" onClick={onClose}>
							Continue
						</button>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default TwoFaSetupSuccessPopUp;
