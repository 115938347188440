import { FC } from 'react';
import Popup from 'reactjs-popup';
import IconSvg from 'ui/Svg/IconSvg';
import { IResetModalProps } from './types';

// ================================================:
export const ResetModal: FC<IResetModalProps> = ({ openModal, closeModal, handleResetModal }) => {
	return (
		<Popup
			open={openModal}
			closeOnDocumentClick
			onClose={closeModal}
			closeOnEscape
			overlayStyle={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
		>
			<div className="popup">
				{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
				<button type="button" className="popup__close-btn" onClick={closeModal} />
				<div className="popup-header popup-header--mb-20">
					<h3 className="popup-header__title">Approve 2FA Reset Request</h3>
				</div>
				<div className="popup-icon popup-icon--type2">
					<IconSvg name="warning-popup" />
				</div>
				<div className="popup-body">
					<div className="popup-text popup-text--center">
						<p>Are you sure you want to approve the 2FA reset request?</p>
					</div>

					<div className="popup-footer">
						<div className="popup-submit popup-submit--column">
							<button
								type="button"
								className="btn btn-primary btn--full"
								onClick={() => {
									handleResetModal();
									closeModal();
								}}
							>
								Confirm
							</button>
							<button
								type="button"
								className="btn-link btn--full justify-content-center"
								onClick={closeModal}
							>
								Cancel
							</button>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};
