import React, { FC } from 'react';

import Popup from 'reactjs-popup';

import IconSvg from 'ui/Svg/IconSvg';
import { IAdminStatusUpdaterProps } from './types';

const AdminStatusUpdater: FC<IAdminStatusUpdaterProps> = ({
	open,
	closeModal,
	event = 'block',
	onConfirm,
}) => {
	return (
		<Popup open={open} onClose={closeModal} lockScroll>
			<div className="popup popup--width-480">
				{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
				<button type="button" className="popup__close-btn" onClick={closeModal} />
				<div className="popup-header popup-header--mb-20">
					<p className="popup-header__title">
						<span className="capitalize">{event}</span> Admin
					</p>
				</div>
				<div className="popup-body">
					<div className="popup-icon popup-icon--status">
						{event === 'block' ? (
							<>
								<IconSvg name="block" />
							</>
						) : (
							<>
								<IconSvg name="unblock" />
							</>
						)}
					</div>
					<div className="popup-text popup-text--type2 popup-text--center">
						<p>Are you sure you want to {event} the admin?</p>
					</div>
				</div>
				<div className="popup-footer">
					<div className="popup-submit popup-submit--column">
						<button type="button" className="btn btn-primary" onClick={onConfirm}>
							Confirm
						</button>
						<button
							type="button"
							className="btn-link btn--full justify-content-center"
							onClick={closeModal}
						>
							Cancel
						</button>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default AdminStatusUpdater;
