import { FC } from 'react';
import { Link } from 'react-router-dom';
import {
	TWO_FA_STATUS_NUM_NEW,
	TWO_FA_STATUS_NUM_REJECT,
	TWO_FA_STATUS_NUM_REJECT_2,
} from 'redux/reducers/users/constants';
import { ROUTES } from 'routes/constants';
import { IAdminTwoFaRequestItem } from '../ResetRequestItem/types';
import { ResetRejectButtons } from './ResetRejectButtons';

export interface IResetItemProps {
	data: IAdminTwoFaRequestItem;
	handleClearSearch?: () => void;
	permission: boolean;
	handleApprove?: (id: number) => void;
	handleReject?: (id: number, message: string) => void;
}

const ResetTwoFaAdminItem: FC<IResetItemProps> = ({
	data,
	handleClearSearch,
	handleApprove,
	handleReject,
	permission,
}) => {
	const showStatus = () => {
		if (data.status === TWO_FA_STATUS_NUM_NEW) {
			return 'New';
		}
		if (data.status === TWO_FA_STATUS_NUM_REJECT || data.status === TWO_FA_STATUS_NUM_REJECT_2) {
			return 'Rejected';
		}
		return 'Accepted';
	};

	const formatedDate = () => {
		if (data.created_at) {
			const dateArr = data?.created_at.slice(0, 10).split('-');
			return `${dateArr[2]}/${dateArr[1]}/${dateArr[0]}`;
		}
		return null;
	};

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Admin ID</p>
				<div className="td__wrap td__wrap--column td__wrap--column-no-gap">
					{data && (
						<Link
							to={{
								pathname: `${ROUTES.adminManagement.EDIT_ADMIN}/${String(data?.user_id)}`,
								state: { id: data?.user_id },
							}}
						>
							{data?.user_id}
						</Link>
					)}
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">E-mail</p>
				<div className="td__wrap td__wrap--column td__wrap--column-no-gap">
					<Link
						to={{
							pathname: `${ROUTES.adminManagement.EDIT_ADMIN}/${String(data?.user_id)}`,
							state: { id: data?.user_id },
						}}
					>
						{data?.user?.email}
					</Link>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Type</p>
				<div className="td__wrap td__wrap--column td__wrap--column-no-gap">
					<div className="td__text">
						<p>2FA</p>
					</div>
				</div>
			</div>

			<div className="td td--status">
				<p className="td-hidden-name">Status</p>
				<div className="td__wrap td__wrap--column td__wrap--column-no-gap">
					<p className={`status status--type2 status--${showStatus().toLowerCase()}`}>
						{showStatus()}
					</p>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Date</p>
				<div className="td__wrap td__wrap--column td__wrap--column-no-gap">
					<p>{formatedDate()}</p>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">IP Address</p>
				<div className="td__wrap td__wrap--column td__wrap--column-no-gap">
					<p>{data?.ip}</p>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Reason</p>
				<div className="td__wrap td__wrap--column td__wrap--column-no-gap">
					<p>{data?.reason}</p>
				</div>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Action</p>
				{permission && (
					<ResetRejectButtons
						id={data.id}
						handleClearSearch={handleClearSearch}
						status={data.status}
						handleApprove={handleApprove}
						handleReject={handleReject}
					/>
				)}
			</div>
		</div>
	);
};

export default ResetTwoFaAdminItem;
