import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import IconSvg from 'ui/Svg/IconSvg';
import { IReferralUser } from 'services/api/referrals/types';

const ReferralsTableRow: FC<IReferralUser> = ({ id, data, email, referral_count }) => (
	<div className="tr">
		<div className="td">
			<p className="td-hidden-name">User ID</p>
			<div className="td__wrap">
				<Link to={`${ROUTES.userManagement.index}/${id}`}>{id}</Link>
			</div>
		</div>
		<div className="td">
			<p className="td-hidden-name">Name</p>
			<div className="td__wrap">
				<p>
					{data.first_name} {data.last_name}
				</p>
			</div>
		</div>
		<div className="td">
			<p className="td-hidden-name">Email</p>
			<div className="td__wrap">
				<p>
					<Link to={`${ROUTES.userManagement.index}/${id}`}>{email}</Link>
				</p>
			</div>
		</div>
		<div className="td">
			<p className="td-hidden-name">Company Name</p>
			<div className="td__wrap">
				<p>{data.company_name}</p>
			</div>
		</div>
		<div className="td td--right">
			<p className="td-hidden-name">Subaccount</p>
			<div className="td__wrap">
				<p>{referral_count}</p>
			</div>
		</div>
		<div className="td td--right">
			<p className="td-hidden-name">Action</p>
			<div className="table-buttons flex-e">
				<Link to={`${ROUTES.referralSystem.referrals}/${id}`} className="btn--icon btn--icon-eye" />
			</div>
		</div>
	</div>
);

export default ReferralsTableRow;
