import React, { FC, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Popup from 'reactjs-popup';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';

import {
	addReferralByUserRequest,
	getFreeReferralsRequest,
} from 'redux/reducers/referrals/reducer';
import { getFreeReferrals } from 'redux/reducers/referrals/selectors';
import { IAccountDetailsParams } from 'components/AccountDetails/types';
import IconSvg from 'ui/Svg/IconSvg';
import useInput from 'hooks/useInput';
import { useDebounce } from 'hooks/useDebounce';
import { SearchField } from './types';
import { IPopUp } from '../types';

const AddReferral: FC<IPopUp> = ({ open, closeModal }) => {
	const { userId } = useParams<IAccountDetailsParams>();
	const dispatch = useDispatch();

	const referrals = useSelector(getFreeReferrals);

	const [openSelect, setOpenSelect] = useState<boolean>(false);

	const [searchField, setSearchField] = useState<string>(SearchField.id);
	const searchValue = useInput('');
	const debouncedSearchValue = useDebounce(searchValue.value, 500);

	const selectRef = useRef<HTMLDivElement | null>(null);

	const selectSearchField = useCallback((field) => {
		setSearchField(field);
		setOpenSelect(false);
	}, []);

	const filteredList = useMemo(() => {
		if (!referrals) return [];

		return referrals.data
			.filter((el) => String(el.id) !== userId)
			.filter((referral) => {
				const id = String(referral.id);
				const lastName = referral.data.last_name;
				const firstName = referral.data.first_name;
				if (debouncedSearchValue) {
					if (searchField === SearchField.id) {
						return id.includes(debouncedSearchValue);
					}

					if (searchField === SearchField.name) {
						if (!!lastName && !!firstName) {
							return (
								(!!lastName && !!firstName && firstName.includes(debouncedSearchValue)) ||
								lastName.includes(debouncedSearchValue)
							);
						}
						return false;
					}
				}
				return true;
			});
	}, [debouncedSearchValue, referrals, searchField, userId]);

	const searchFieldsArray = Object.keys(SearchField) as Array<keyof typeof SearchField>;

	const handleOpenSelect = useCallback(() => {
		setOpenSelect(!openSelect);
	}, [openSelect]);

	const handlerAddReferral = useCallback(
		(id) => {
			dispatch(addReferralByUserRequest({ userId, referral_id: id }));
			closeModal();
		},
		[closeModal, dispatch, userId],
	);

	useLayoutEffect(() => {
		const params = {
			per_page: referrals?.total,
		};
		dispatch(getFreeReferralsRequest(params));
	}, [dispatch, referrals?.total]);

	useLayoutEffect(() => {
		const handleOutsideClick = (event: MouseEvent) => {
			if (!selectRef.current?.contains(event.target as Node)) {
				setOpenSelect(false);
			}
		};
		if (openSelect) {
			document.addEventListener('mousedown', handleOutsideClick);
		}
		return () => document.removeEventListener('mousedown', handleOutsideClick);
	}, [openSelect]);

	return (
		<Popup open={open} onClose={closeModal} lockScroll>
			<div className="popup popup--width-480">
				{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
				<button type="button" className="popup__close-btn" onClick={closeModal} />
				<div className="popup-header popup-header--mb-20">
					<p className="popup-header__title">Add Referral</p>
				</div>
				<div className="popup-body">
					<div className="popup-search mb-10">
						<div className={openSelect ? 'select active' : 'select'} ref={selectRef}>
							<button className="select__current" type="button" onClick={handleOpenSelect}>
								<div className="select__current-text">
									<p>{searchField}</p>
								</div>
								<span className="select__current-arrow" />
							</button>
							<AnimatePresence>
								{openSelect && (
									<motion.div
										className="select__drop"
										initial={{ opacity: 0 }}
										animate={{ opacity: 1, transition: { duration: 0.3 } }}
										exit={{ opacity: 0, transition: { duration: 0.3 } }}
									>
										<div className="select__drop-scroll">
											<div className="select__drop-item">
												<ul>
													{searchFieldsArray.map((field) => (
														<li key={field}>
															<button
																onClick={() => selectSearchField(SearchField[field])}
																type="button"
															>
																{SearchField[field]}
															</button>
														</li>
													))}
												</ul>
											</div>
										</div>
									</motion.div>
								)}
							</AnimatePresence>
						</div>
						<div className="input">
							<div className="input-wrapper">
								<input
									className="input-item"
									type="text"
									placeholder={`Search by ${searchField}`}
									{...searchValue}
								/>
								<div className="input-icon">
									<svg
										width="21"
										height="21"
										viewBox="0 0 21 21"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M9.76639 1.52802C5.21636 1.52802 1.52783 5.21654 1.52783 9.76657C1.52783 14.3166 5.21636 18.0051 9.76639 18.0051C14.3164 18.0051 18.0049 14.3166 18.0049 9.76657C18.0049 5.21654 14.3164 1.52802 9.76639 1.52802ZM0.027832 9.76657C0.027832 4.38812 4.38793 0.0280151 9.76639 0.0280151C15.1448 0.0280151 19.5049 4.38812 19.5049 9.76657C19.5049 15.145 15.1448 19.5051 9.76639 19.5051C4.38793 19.5051 0.027832 15.145 0.027832 9.76657Z"
											fill="#626A85"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M15.487 15.9555C15.7796 15.6622 16.2544 15.6616 16.5477 15.9541L20.0717 19.469C20.365 19.7615 20.3656 20.2363 20.0731 20.5296C19.7806 20.8229 19.3057 20.8235 19.0125 20.531L15.4884 17.0161C15.1952 16.7236 15.1945 16.2487 15.487 15.9555Z"
											fill="#626A85"
										/>
									</svg>
								</div>
							</div>
						</div>
					</div>
					<div className="add-account-search-list">
						{filteredList.length ? (
							filteredList.map((referral) => (
								<button
									type="button"
									className="add-account-search-item"
									key={referral.id}
									onClick={() => handlerAddReferral(referral.id)}
								>
									{referral.id}{' '}
									<span>
										{referral.data.first_name} {referral.data.last_name}
									</span>
								</button>
							))
						) : (
							<div className="text--center">User not found</div>
						)}
					</div>
				</div>
				<div className="popup-footer">
					<div className="popup-submit popup-submit--column">
						<button type="button" className="btn btn-primary btn--full" onClick={closeModal}>
							Confirm
						</button>
						<button
							type="button"
							className="btn-link btn--full justify-content-center"
							onClick={closeModal}
						>
							Cancel
						</button>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default AddReferral;
