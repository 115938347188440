import React, { FC, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import {
	getPendingDepositFileRequest,
	getPendingDepositRequest,
} from 'redux/reducers/transactions/reducer';
import {
	getPendingCryptoWithdrawalFilters,
	getPendingFiatWithdrawalFilters,
} from 'redux/reducers/transactionsFilters/selectors';
import { getPendingDeposit, getPendingDepositLoading } from 'redux/reducers/transactions/selectors';
import { convertFilterObjectToParamsObject } from 'services/utils/convertFilterObjectToParamsObject';
import { EPendingDepositsType, IApiGetTransactionParams } from 'services/api/transactions/types';
import { addFilter, deleteFilter, resetFilters } from 'redux/reducers/transactionsFilters/reducer';
import { EFiltersType, ETransactionsFilters } from 'redux/reducers/transactionsFilters/types';
import SearchWithSelect, { ISearchSelectArr } from 'ui/SearchWithSelect/SearchWithSelect';
import { CSVFileIcon, XLSFileIcon } from 'assets/inline-svg';
import TableBodyNoData from 'ui/TableBodyNoData/TableBodyNoData';
import PerPageSelect from 'ui/PerPageSelect/PerPageSelect';
import IconSvg from 'ui/Svg/IconSvg';
import ReportsFilters from 'ui/ActiveFilters/ReportsFilters';

import { getPermissions } from 'redux/reducers/auth/selectors';
import { getCryptoCurrencyData, getFiatCurrencyData } from 'redux/reducers/currency/selectors';
import { EPermissionNames } from 'redux/reducers/auth/types';
import {
	STATUS_PENDING_DEPOSIT_CRYPTO_FILTER_OPTIONS,
	STATUS_PENDING_DEPOSIT_FIAT_FILTER_OPTIONS,
	STATUS_PENDING_WITHDRAWAL_FILTER_OPTIONS,
} from 'redux/reducers/transactionsFilters/constants';
import cn from 'classnames';
import { THistoryFiltersOption } from 'components/AccountDetails/TransactionHistoryTab/CryptoHistory/types';
import PendingDepositsTableRow from './PendingDepositsTableRow/PendingDepositsTableRow';
import PendingDepositsTableHeader from './PendingDepositsTableHeader/PendingDepositsTableHeader';
import { ITransactionsTableSortedParams } from '../../types';
import Loading from '../../../../layouts-elements/Loading/Loading';

export interface IPendingWithdrawalTable {
	type: EPendingDepositsType;
}

// const FiltersSearchArray = [{ name: 'User ID', value: ETransactionsFilters.USER_ID }];
const initFiltersSearchArray = [{ name: 'User ID', value: ETransactionsFilters.USER_ID }];

const PendingDepositsTable: FC<IPendingWithdrawalTable> = ({ type }) => {
	const [filtersSearchArray, setFiltersSearchArray] = useState(initFiltersSearchArray);

	const dispatch = useDispatch();
	const pendingDeposit = useSelector(getPendingDeposit);
	const activeFilters = useSelector(
		type === EPendingDepositsType.CRYPTO
			? getPendingCryptoWithdrawalFilters
			: getPendingFiatWithdrawalFilters,
	);
	const profitWalletsLoading = useSelector(getPendingDepositLoading);
	const permission = useSelector(getPermissions)?.[EPermissionNames.TRANSFER_TRADE_REPORT].editable;

	const [sorted, setSorted] = useState<ITransactionsTableSortedParams | null>(null);
	const [currentPage, setCurrentPage] = useState(pendingDeposit?.current_page || 1);
	const [resetSearchValue, setResetSearchValue] = useState(false);
	const [perPage, setPerPage] = useState<number | undefined>(10);
	const [reset, setReset] = useState(false);

	const handleSetPerPage = (value: number | undefined) => {
		setPerPage(value || pendingDeposit?.total);
		setCurrentPage(1);
	};

	const handlePageClick = ({ selected }: { selected: number }) => {
		setCurrentPage(selected + 1);
	};

	const pageCount = pendingDeposit?.last_page ? pendingDeposit.last_page : 1;

	useLayoutEffect(() => {
		if (currentPage > pageCount) {
			setCurrentPage(1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pendingDeposit?.last_page]);

	useLayoutEffect(() => {
		const initParams = {
			per_page: perPage,
			current_page: currentPage,
		};

		const sortedParamsObj = sorted ? { [`${sorted?.type}_sort`]: sorted?.value } : {};
		const filterParams = convertFilterObjectToParamsObject(activeFilters);

		const params: IApiGetTransactionParams = {
			...initParams,
			...filterParams,
			...sortedParamsObj,
		};
		dispatch(getPendingDepositRequest({ type, params }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [type, sorted, activeFilters, currentPage, perPage]);

	useLayoutEffect(() => {
		return () => {
			dispatch(resetFilters(EFiltersType.TRANSACTIONS_FIAT_PENDING_WITHDRAWALS));
			dispatch(resetFilters(EFiltersType.TRANSACTIONS_CRYPTO_PENDING_WITHDRAWALS));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useLayoutEffect(() => {
		type === EPendingDepositsType.CRYPTO &&
			dispatch(resetFilters(EFiltersType.TRANSACTIONS_FIAT_PENDING_WITHDRAWALS));
		type === EPendingDepositsType.FIAT &&
			dispatch(resetFilters(EFiltersType.TRANSACTIONS_CRYPTO_PENDING_WITHDRAWALS));

		if (type === EPendingDepositsType.CRYPTO) {
			setFiltersSearchArray([{ name: 'User ID', value: ETransactionsFilters.USER_ID }]);
		} else {
			setFiltersSearchArray(initFiltersSearchArray);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [type]);

	const handleResetSearchValue = () => {
		setResetSearchValue(!resetSearchValue);
	};

	const handleDeleteFilter = (filterType: ETransactionsFilters) => {
		handleResetSearchValue();
		dispatch(
			deleteFilter({
				type: filterType,
				filterType:
					type === EPendingDepositsType.CRYPTO
						? EFiltersType.TRANSACTIONS_CRYPTO_PENDING_WITHDRAWALS
						: EFiltersType.TRANSACTIONS_FIAT_PENDING_WITHDRAWALS,
			}),
		);
	};

	const handleResetFilters = () => {
		handleResetSearchValue();
		setReset(true);
		dispatch(
			resetFilters(
				type === EPendingDepositsType.CRYPTO
					? EFiltersType.TRANSACTIONS_CRYPTO_PENDING_WITHDRAWALS
					: EFiltersType.TRANSACTIONS_FIAT_PENDING_WITHDRAWALS,
			),
		);
	};

	useLayoutEffect(() => {
		setSorted(null);
		setPerPage(10);
		setCurrentPage(1);
		setResetSearchValue(false);
		setReset(true);
		handleResetSearchValue();
		return () => {
			dispatch(
				resetFilters(
					type === EPendingDepositsType.CRYPTO
						? EFiltersType.TRANSACTIONS_CRYPTO_PENDING_WITHDRAWALS
						: EFiltersType.TRANSACTIONS_FIAT_PENDING_WITHDRAWALS,
				),
			);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [type]);

	const handleSearch = (searchField: ISearchSelectArr, currentSearchValue: string) => {
		if (currentSearchValue === '') {
			dispatch(
				deleteFilter({
					type: searchField.value as ETransactionsFilters,
					filterType:
						type === EPendingDepositsType.CRYPTO
							? EFiltersType.TRANSACTIONS_CRYPTO_PENDING_WITHDRAWALS
							: EFiltersType.TRANSACTIONS_FIAT_PENDING_WITHDRAWALS,
				}),
			);
		} else {
			dispatch(
				addFilter({
					type: searchField.value as ETransactionsFilters,
					value: currentSearchValue,
					name: `${searchField.name}: ${currentSearchValue}`,
					filterType:
						type === EPendingDepositsType.CRYPTO
							? EFiltersType.TRANSACTIONS_CRYPTO_PENDING_WITHDRAWALS
							: EFiltersType.TRANSACTIONS_FIAT_PENDING_WITHDRAWALS,
				}),
			);
		}
	};

	const handleDownloadCsvFile = () => {
		dispatch(getPendingDepositFileRequest({ fileType: 'csv', assetType: type }));
	};

	const handleDownloadXlsxFile = () => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		dispatch(getPendingDepositFileRequest({ fileType: 'xls', assetType: type }));
	};
	const onChangeDate = (startDate: Date, endDate: Date) => {
		setReset(false);
		dispatch(
			addFilter({
				type: ETransactionsFilters.DATE,
				value: { date_from: startDate, date_to: endDate },
				filterType:
					type === EPendingDepositsType.CRYPTO
						? EFiltersType.TRANSACTIONS_CRYPTO_PENDING_WITHDRAWALS
						: EFiltersType.TRANSACTIONS_FIAT_PENDING_WITHDRAWALS,
			}),
		);
	};

	const handleAssetChange = ({ name, value }: THistoryFiltersOption) => {
		setReset(false);
		dispatch(
			addFilter({
				name,
				value: value as string,
				type: ETransactionsFilters.ASSET_ID,
				filterType:
					type === EPendingDepositsType.CRYPTO
						? EFiltersType.TRANSACTIONS_CRYPTO_PENDING_WITHDRAWALS
						: EFiltersType.TRANSACTIONS_FIAT_PENDING_WITHDRAWALS,
			}),
		);
	};

	const cryptoCurrencyData = useSelector(getCryptoCurrencyData);
	const cryptoCurrencyFilterOptions = cryptoCurrencyData.map(({ id, code, img_path }) => ({
		id,
		name: code.toUpperCase(),
		value: id,
		path: img_path,
		code,
	}));
	const fiatCurrencyData = useSelector(getFiatCurrencyData);
	const fiatCurrencyFilterOptions = fiatCurrencyData.map(({ id, code, img_path }) => ({
		id,
		name: code.toUpperCase(),
		value: id,
		path: img_path,
		code,
	}));

	const handleStatusChange = ({ name, value }: THistoryFiltersOption) => {
		setReset(false);
		dispatch(
			addFilter({
				name,
				value: value as string,
				type: ETransactionsFilters.STATUS,
				filterType:
					type === EPendingDepositsType.CRYPTO
						? EFiltersType.TRANSACTIONS_CRYPTO_PENDING_WITHDRAWALS
						: EFiltersType.TRANSACTIONS_FIAT_PENDING_WITHDRAWALS,
			}),
		);
	};

	return (
		<>
			{/* <SearchWithSelect
					resetValue={resetSearchValue}
					selectArr={FiltersSearchArray}
					handleSearch={handleSearch}
				/>
				<button type="button" className="filters__download-button" onClick={handleDownloadCsvFile}>
					<CSVFileIcon />
					Download
				</button>
				<button type="button" className="filters__download-button" onClick={handleDownloadXlsxFile}>
					<XLSFileIcon />
					Download
				</button> */}

			<ReportsFilters
				handleResetFilters={handleResetFilters}
				handleDateChange={onChangeDate}
				handleChangeAsset={handleAssetChange}
				currencyOptions={
					type === EPendingDepositsType.CRYPTO
						? cryptoCurrencyFilterOptions
						: fiatCurrencyFilterOptions
				}
				handleStatusChange={handleStatusChange}
				statusOptions={
					type === EPendingDepositsType.CRYPTO
						? STATUS_PENDING_DEPOSIT_CRYPTO_FILTER_OPTIONS
						: STATUS_PENDING_DEPOSIT_FIAT_FILTER_OPTIONS
				}
				searchArray={filtersSearchArray}
				handleSearch={handleSearch}
				resetValue={resetSearchValue}
				reset={reset}
			/>
			<div className="table-block">
				<div className="table-wrapper table-wrapper--with-y-scroll">
					<div
						className={cn('table table--type2', {
							'table--transactions-pending-deposit': type === EPendingDepositsType.CRYPTO,
							'table--transactions-pending-deposit-fiat': type === EPendingDepositsType.FIAT,
						})}
					>
						<div className="table-header">
							<PendingDepositsTableHeader assetType={type} setSorted={setSorted} />
						</div>
						<div
							className={`table-body 
								${pendingDeposit?.data.length === 4 ? 'table-body--four-elements' : ''}
								${pendingDeposit?.data.length === 3 ? 'table-body--three-elements' : ''}
								${pendingDeposit?.data.length === 2 ? 'table-body--two-elements' : ''}
								${pendingDeposit?.data.length === 1 ? 'table-body--one-elements' : ''}
							`}
						>
							{!profitWalletsLoading && (
								<>
									{' '}
									{pendingDeposit?.data.length ? (
										pendingDeposit?.data.map((item) => (
											<PendingDepositsTableRow
												key={item.id}
												{...item}
												assetType={type}
												permission={!!permission}
											/>
										))
									) : (
										<TableBodyNoData />
									)}
								</>
							)}

							{profitWalletsLoading ? <Loading /> : null}
						</div>
					</div>
				</div>
				{!!pendingDeposit?.data.length && (
					<div className="table-footer">
						<div className="pagination-block pagination-block--full">
							<PerPageSelect onChange={handleSetPerPage} />
							{!profitWalletsLoading && pageCount > 1 && (
								<ReactPaginate
									breakLabel="..."
									pageCount={pageCount}
									onPageChange={handlePageClick}
									forcePage={currentPage - 1}
									className="pagination"
									activeClassName="active"
									previousClassName="pagination__arrow pagination__arrow--prev"
									nextClassName="pagination__arrow pagination__arrow--next"
									previousLabel=""
									nextLabel=""
								/>
							)}
							<div className="button-wrap">
								{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
								<button type="button" className="btn btn--csv" onClick={handleDownloadCsvFile} />
								{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
								<button type="button" className="btn btn--xlsx" onClick={handleDownloadXlsxFile} />
							</div>
						</div>
					</div>
				)}
			</div>
			{/* {!!pendingDeposit?.data.length && (
				<div className="table-pagination">
					<PerPageSelect onChange={handleSetPerPage} />
					{!profitWalletsLoading && pageCount > 1 && (
						<ReactPaginate
							breakLabel="..."
							pageCount={pageCount}
							onPageChange={handlePageClick}
							forcePage={currentPage - 1}
							className="pagination"
							activeClassName="active"
							previousClassName="pagination__arrow pagination__arrow--prev"
							nextClassName="pagination__arrow pagination__arrow--next"
							previousLabel=""
							nextLabel=""
						/>
					)}
				</div>
			)} */}
		</>
	);
};

export default PendingDepositsTable;
