import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import Popup from 'reactjs-popup';
import { deleteAdminRequest } from 'redux/reducers/admins/reducer';
import { IPopUpData } from 'redux/reducers/popUp/types';
import IconSvg from 'ui/Svg/IconSvg';
import { IPopUp } from '../types';

const DeleteAdminPopUp: FC<IPopUp & IPopUpData> = ({
	open,
	closeModal,
	title,
	message,
	payload,
}) => {
	const dispatch = useDispatch();

	const handleDeleteAdmin = () => {
		const data = {
			...payload,
			message,
		};
		dispatch(deleteAdminRequest(data));
		closeModal();
	};

	return (
		<Popup open={open} closeOnDocumentClick onClose={closeModal}>
			<div className="popup popup--width-480">
				{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
				<button type="button" className="popup__close-btn" onClick={closeModal} />
				<div className="popup-header popup-header--mb-20">
					<p className="popup-header__title">Remove Admin</p>
				</div>
				<div className="popup-icon popup-icon--type2">
					<IconSvg name="delete-user" />
				</div>
				<div className="popup-text">
					<p>Are you sure you want to delete the admin?</p>
				</div>
				<div className="popup-footer">
					<div className="popup-submit popup-submit--column">
						<button onClick={handleDeleteAdmin} type="button" className="btn btn-primary">
							Delete
						</button>
						<button
							onClick={closeModal}
							type="button"
							className="btn-link btn--full justify-content-center"
						>
							Cancel
						</button>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default DeleteAdminPopUp;
