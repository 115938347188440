/* eslint-disable react/jsx-props-no-spreading */
import { ChangeEvent, FC } from 'react';
import cn from 'classnames';
import FormControl from 'ui/Formik/FormConrol';
import { IAdminInput } from './types';

export interface IInput extends IAdminInput {
	onChange?: (value: string) => void;
	disable?: boolean;
	onBlur?: (event: any) => void;
}

// ==========================================:
const Input: FC<IInput> = (props) => {
	const {
		ariaLabel,
		type,
		field,
		placeholder,
		inputMode,
		onKeyUp,
		className,
		onChange,
		disable,
		onBlur,
	} = props;
	const changeInputValue = ({ target }: ChangeEvent<HTMLInputElement>) => {
		field.onChange({
			target: {
				value: field.name === 'phone' ? target.value.replace(/\D/g, '') : target.value,
				name: field.name,
			},
		});
		!!onChange && onChange(target.value);
	};

	return (
		<FormControl ariaLabel={ariaLabel} {...props}>
			{type !== 'textarea' ? (
				<input
					className={cn('input-item', className)}
					{...field}
					type={type}
					disabled={disable}
					placeholder={placeholder}
					onKeyUp={onKeyUp}
					onChange={changeInputValue}
					onBlur={onBlur}
					onWheel={(e) => e.currentTarget.blur()}
					inputMode={inputMode || null}
				/>
			) : (
				<textarea
					className={cn('input-item textarea_item', className)}
					{...field}
					onKeyUp={onKeyUp}
					placeholder={placeholder}
					onChange={changeInputValue}
					onBlur={onBlur}
					disabled={disable}
					inputMode={inputMode || null}
				/>
			)}
		</FormControl>
	);
};

export default Input;
