import React, { useLayoutEffect, useState } from 'react';
import { EFiltersType, ETransactionsFilters } from 'redux/reducers/transactionsFilters/types';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { convertFilterObjectToParamsObject } from 'services/utils/convertFilterObjectToParamsObject';
import { IApiGetTransactionParams } from 'services/api/transactions/types';
import { getTradesFilters } from 'redux/reducers/transactionsFilters/selectors';
import { getTrades, getTradesLoading } from 'redux/reducers/transactions/selectors';
import { getTradesFileRequest, getTradesRequest } from 'redux/reducers/transactions/reducer';
import { addFilter, deleteFilter, resetFilters } from 'redux/reducers/transactionsFilters/reducer';
import { CSVFileIcon, XLSFileIcon } from 'assets/inline-svg';
import SearchWithSelect, { ISearchSelectArr } from 'ui/SearchWithSelect/SearchWithSelect';
import TableBodyNoData from 'ui/TableBodyNoData/TableBodyNoData';
import PerPageSelect from 'ui/PerPageSelect/PerPageSelect';
import { getCurrencyData } from 'redux/reducers/currency/selectors';
import { getAdminId } from 'redux/reducers/auth/selectors';
import IconSvg from 'ui/Svg/IconSvg';
import ActiveFilters from 'ui/ActiveFilters/ActiveFilters';
import ActiveReportsFilters from 'ui/ActiveFilters/ActiveReportsFilters';
import { THistoryFiltersOption } from 'components/AccountDetails/TransactionHistoryTab/CryptoHistory/types';
import ActiveTradeFilters from 'ui/ActiveFilters/ActiveTradeFilters';
import TradesTableHeader from '../TradesTableHeader/TradesTableHeader';
import TradesTableRow from '../TradesTableRow/TradesTableRow';
import { ITransactionsTableSortedParams } from '../../types';
import Loading from '../../../../layouts-elements/Loading/Loading';
import AddTradeTransaction from '../../../../layouts-elements/PopUp/AddTradeTransaction/AddTradeTransaction';

const FiltersSearchArray = [{ name: 'Tx ID', value: ETransactionsFilters.TX_ID }];

const TradesTable = () => {
	const dispatch = useDispatch();
	const adminId = useSelector(getAdminId) || 1;
	const activeFilters = useSelector(getTradesFilters);
	const trades = useSelector(getTrades);
	const tradesLoading = useSelector(getTradesLoading);
	const currency = useSelector(getCurrencyData);

	const [addTradeTransactionPopup, setAddTradeTransactionPopup] = useState(false);

	const [reset, setReset] = useState(false);

	const [sorted, setSorted] = useState<ITransactionsTableSortedParams | null>(null);
	const [currentPage, setCurrentPage] = useState(trades?.current_page || 1);
	const [resetSearchValue, setResetSearchValue] = useState(false);
	const [perPage, setPerPage] = useState<number | undefined>(10);

	const handleOpenAddTradeTransactionPopup = () => {
		setAddTradeTransactionPopup(true);
	};
	const handleCloseAddTradeTransactionPopup = () => {
		setAddTradeTransactionPopup(false);
	};

	const handleSetPerPage = (value: number | undefined) => {
		setPerPage(value || trades?.total);
		setCurrentPage(1);
	};

	const handlePageClick = ({ selected }: { selected: number }) => {
		setCurrentPage(selected + 1);
	};

	const pageCount = trades?.last_page ? trades.last_page : 1;

	useLayoutEffect(() => {
		if (currentPage > pageCount) {
			setCurrentPage(1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [trades?.last_page]);

	useLayoutEffect(() => {
		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
		const sortedParamsObj = sorted ? { [`${sorted?.type}_sort`]: sorted?.value } : {};
		const filterParams = convertFilterObjectToParamsObject(activeFilters);

		const params: IApiGetTransactionParams = {
			per_page: perPage,
			current_page: currentPage,
			...filterParams,
			...sortedParamsObj,
		};

		dispatch(getTradesRequest(params));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sorted, activeFilters, currentPage, perPage]);

	useLayoutEffect(() => {
		setCurrentPage(1);
		return () => {
			dispatch(resetFilters(EFiltersType.TRANSACTIONS_TRADES));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleResetSearchValue = () => {
		setResetSearchValue(!resetSearchValue);
	};

	const handleDeleteFilter = (filterType: ETransactionsFilters) => {
		handleResetSearchValue();
		dispatch(deleteFilter({ type: filterType, filterType: EFiltersType.TRANSACTIONS_TRADES }));
	};

	const handleResetFilters = () => {
		handleResetSearchValue();
		setReset(true);
		dispatch(resetFilters(EFiltersType.TRANSACTIONS_TRADES));
	};

	const handleSearch = (searchField: ISearchSelectArr, currentSearchValue: string) => {
		if (currentSearchValue === '') {
			dispatch(
				deleteFilter({
					type: searchField.value as ETransactionsFilters,
					filterType: EFiltersType.TRANSACTIONS_TRADES,
				}),
			);
		} else {
			dispatch(
				addFilter({
					type: searchField.value as ETransactionsFilters,
					value: currentSearchValue,
					name: `${searchField.name}: ${currentSearchValue}`,
					filterType: EFiltersType.TRANSACTIONS_TRADES,
				}),
			);
		}
	};

	const handleDownloadCsvFile = () => {
		dispatch(getTradesFileRequest({ fileType: 'csv', adminId }));
	};

	const handleDownloadXlsxFile = () => {
		dispatch(getTradesFileRequest({ fileType: 'xlsx', adminId }));
	};

	const onChangeDate = (startDate: Date, endDate: Date) => {
		setReset(false);
		dispatch(
			addFilter({
				type: ETransactionsFilters.DATE,
				value: { date_from: startDate, date_to: endDate },
				filterType: EFiltersType.TRANSACTIONS_TRADES,
			}),
		);
	};
	const currencyFilterOptions = currency.map(({ id, code, img_path }) => ({
		id,
		name: code.toUpperCase(),
		value: id,
		path: img_path,
		code,
	}));
	const selectChangeCurrencySell = ({ value, name }: THistoryFiltersOption) => {
		setReset(false);
		if (!value) {
			dispatch(
				deleteFilter({
					type: ETransactionsFilters.SELL,
					filterType: EFiltersType.TRANSACTIONS_TRADES,
				}),
			);
		} else {
			dispatch(
				addFilter({
					name,
					value: name,
					type: ETransactionsFilters.SELL,
					filterType: EFiltersType.TRANSACTIONS_TRADES,
				}),
			);
		}
	};
	const selectChangeCurrencyBuy = ({ value, name }: THistoryFiltersOption) => {
		setReset(false);
		if (!value) {
			dispatch(
				deleteFilter({
					type: ETransactionsFilters.BUY,
					filterType: EFiltersType.TRANSACTIONS_TRADES,
				}),
			);
		} else {
			dispatch(
				addFilter({
					name,
					value: name,
					type: ETransactionsFilters.BUY,
					filterType: EFiltersType.TRANSACTIONS_TRADES,
				}),
			);
		}
	};
	return (
		<>
			{/* <div className="filters__search-block filters__search-block--transactions-trades mb-20">
				<SearchWithSelect
					resetValue={resetSearchValue}
					selectArr={FiltersSearchArray}
					handleSearch={handleSearch}
				/>
				<button type="button" className="filters__download-button" onClick={handleDownloadCsvFile}>
					<CSVFileIcon />
					Download
				</button>
				<button type="button" className="filters__download-button" onClick={handleDownloadXlsxFile}>
					<XLSFileIcon />
					Download
				</button>
			</div> */}
			<div className="user-management-body user-management-body--type2">
				<div className="tabs-buttons-wrapper tabs-buttons-wrapper--mb-24">
					<div />
					<button
						type="button"
						onClick={handleOpenAddTradeTransactionPopup}
						className="btn btn-primary"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="21"
							height="20"
							viewBox="0 0 21 20"
							fill="none"
						>
							<path
								d="M9.87435 15.8307V10.6224H4.66602V9.3724H9.87435V4.16406H11.1243V9.3724H16.3327V10.6224H11.1243V15.8307H9.87435Z"
								fill="white"
							/>
						</svg>
						Add New Transaction
					</button>
				</div>
			</div>

			<ActiveTradeFilters
				handleResetFilters={handleResetFilters}
				handleDateChange={onChangeDate}
				searchArray={FiltersSearchArray}
				handleSearch={handleSearch}
				resetValue={resetSearchValue}
				currencyOptions={currencyFilterOptions}
				handleChangeCurrencySell={selectChangeCurrencySell}
				handleChangeCurrencyBuy={selectChangeCurrencyBuy}
				reset={reset}
			/>
			<div className="table-block">
				<div className="table-wrapper table-wrapper--with-y-scroll">
					<div className="table table--type2 table--transactions-trades">
						<div className="table-header">
							<TradesTableHeader setSorted={setSorted} />
						</div>
						<div className="table-body">
							{!tradesLoading && (
								<>
									{' '}
									{trades?.data.length ? (
										trades?.data.map((item) => <TradesTableRow key={item.id} {...item} />)
									) : (
										<TableBodyNoData />
									)}
								</>
							)}
							{tradesLoading ? <Loading /> : null}
						</div>
					</div>
				</div>
				{!!trades?.data.length && (
					<div className="table-footer">
						<div className="pagination-block pagination-block--full">
							<PerPageSelect onChange={handleSetPerPage} />
							{!tradesLoading && pageCount > 1 && (
								<ReactPaginate
									breakLabel="..."
									pageCount={pageCount}
									onPageChange={handlePageClick}
									forcePage={currentPage - 1}
									className="pagination"
									activeClassName="active"
									previousClassName="pagination__arrow pagination__arrow--prev"
									nextClassName="pagination__arrow pagination__arrow--next"
									previousLabel=""
									nextLabel=""
								/>
							)}
							<div className="button-wrap">
								{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
								<button type="button" className="btn btn--csv" onClick={handleDownloadCsvFile} />
								{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
								<button type="button" className="btn btn--xlsx" onClick={handleDownloadXlsxFile} />
							</div>
						</div>
					</div>
				)}
			</div>
			<AddTradeTransaction
				closeModal={handleCloseAddTradeTransactionPopup}
				open={addTradeTransactionPopup}
			/>
		</>
	);
};

export default TradesTable;
