import React, { useLayoutEffect, useState } from 'react';
import login from 'assets/dist/img/login-icon.svg';
import LoginFirst from 'components/LoginFirst';
import { Helmet } from 'react-helmet';
import Logo from 'assets/img/top_logo.svg';

const LoginFirstPage = () => {
	return (
		<>
			<Helmet>
				<body className="login-page" />
			</Helmet>
			<section className="login-section">
				<a href="/" className="login-section__logo">
					<img src={Logo} alt="" />
				</a>
				<div className="container">
					<div className="login">
						<div className="create-account-progress">
							<div className="create-account-progress__step active">
								<div className="create-account-progress__step-text">
									<p>Create Password</p>
								</div>
								<div className="create-account-progress__step-number">1</div>
							</div>
							<div className="create-account-progress__line" />
							<div className="create-account-progress__step">
								<div className="create-account-progress__step-text">
									<p>2FA Setup</p>
								</div>
								<div className="create-account-progress__step-number">2</div>
							</div>
						</div>

						<div className="login-form">
							<div className="login-form__title">
								<p>Create Password</p>
								<p className="login-form__subtitle">Please enter a strong and unique password.</p>
							</div>

							<LoginFirst />
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default LoginFirstPage;
