import React from 'react';

const ReferralsTableHeader = () => {
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<div className="td-name">
						<p>User ID</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Name</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Email</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Company Name</p>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Subaccount</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name" />
				</div>
			</div>
		</div>
	);
};

export default ReferralsTableHeader;
