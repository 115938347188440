/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import IconSvg from 'ui/Svg/IconSvg';
import { deleteWalletAddressRequest } from 'redux/reducers/walletAddresses/reducer';

import { setPopUpData } from 'redux/reducers/popUp/reducer';

import { IWalletAddressRequestPayload } from 'redux/reducers/walletAddresses/types';
import {
	getFeeCurrenciesRequest,
	putFeeCurrenciesRequest,
	putFeeRequest,
} from 'redux/reducers/fee/reducer';
import { getFeeStoreData, updateState } from 'redux/reducers/fee/selectors';
import { getPermissions } from 'redux/reducers/auth/selectors';
import { EPermissionNames } from 'redux/reducers/auth/types';
import CurrenclesItem from './CurrenclesItem/CurrenclesItem';
import SpreadManagementItem from '../SpreadManagement/SpreadManagementItem/SpreadManagementItem';
import TableBodyNoData from '../../../ui/TableBodyNoData/TableBodyNoData';
import Loading from '../../../layouts-elements/Loading/Loading';

const CurrenclesFee = () => {
	const dispatch = useDispatch();
	const fee = useSelector(getFeeStoreData);
	const { loadingCurrenciesGet } = useSelector(getFeeStoreData);
	const permissions = useSelector(getPermissions);
	const [tab, setTab] = useState('crypto');

	const updateStateReqvest = useSelector(updateState);
	const perPage = 9;
	const totalPage = fee.dataCurrencies.total;

	useLayoutEffect(() => {
		const data = {
			per_page: -1,
			current_page: 1,
			search_field: 'type',
			type: tab,
		};
		dispatch(getFeeCurrenciesRequest(data));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tab]);

	const handlePageClick = ({ selected }: { selected: number }) => {
		const data = {
			per_page: -1,
			current_page: selected + 1,
			type: tab,
		};
		dispatch(getFeeCurrenciesRequest(data));
	};

	const onDelete = (id: number, nickname: string) => {
		dispatch(
			setPopUpData({
				message: `Your wallet address "${nickname}" was deleted successfully.`,
			}),
		);
		dispatch(deleteWalletAddressRequest({ id }));
	};

	const deleteHandler = (id: number, nickname: string) => {
		dispatch(
			setPopUpData({
				title: 'Delete Wallet Address',
				message: 'Are you sure you what to delete?',
				confirmHandler: () => onDelete(id, nickname),
			}),
		);
	};

	const updateHandler = (obj: IWalletAddressRequestPayload, assetName: string) => {
		const action = Object.values(obj.data)[0] ? 'enabled' : 'disabled';
		dispatch(putFeeCurrenciesRequest({ data: obj, type: tab, assetName, action }));
	};

	return (
		<>
			<div className="tabs-buttons tabs-buttons--default-fees">
				<button
					type="button"
					onClick={() => setTab('crypto')}
					className={tab === 'crypto' ? 'active' : ''}
				>
					Crypto
				</button>
				<button
					type="button"
					onClick={() => setTab('fiat')}
					className={tab === 'fiat' ? 'active' : ''}
				>
					Fiat
				</button>
			</div>
			<div className="table-block">
				<div className="table-wrapper">
					<div className="table table--fee-management-currencles">
						<div className="table-header">
							<div className="tr">
								<div className="td">
									<div className="td-name">
										<p>Currency</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Deposit</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Withdrawal</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Trade</p>
									</div>
								</div>
							</div>
						</div>
						<div className="table-body">
							{!loadingCurrenciesGet && (
								<>
									{' '}
									{fee?.dataCurrencies.length ? (
										fee?.dataCurrencies.map((e: any) => (
											<CurrenclesItem
												key={e.id}
												deleteHandler={deleteHandler}
												updateHandler={updateHandler}
												data={e}
												permission={!!permissions?.[EPermissionNames.FEE_MANAGEMENT].editable}
											/>
										))
									) : (
										<TableBodyNoData />
									)}
								</>
							)}
							{loadingCurrenciesGet ? <Loading /> : null}
						</div>
					</div>
				</div>
			</div>

			{/* {totalPage > perPage && (
				<div style={{ marginBottom: '32px' }} className="pagination-block">
					<ReactPaginate
						breakLabel="..."
						pageCount={Math.ceil(totalPage / perPage)}
						onPageChange={handlePageClick}
						className="pagination"
						activeClassName="active"
						previousClassName="arrow"
						nextClassName="arrow"
						previousLabel={<IconSvg name="keyboard-arrow-left" w="10" h="16" />}
						nextLabel={<IconSvg name="keyboard-arrow-right" w="10" h="16" />}
					/>
				</div>
			)} */}
		</>
	);
};

export default CurrenclesFee;
