/* eslint-disable react-hooks/exhaustive-deps */

import { FC, memo } from 'react';
import DepositAccountsTab from 'components/WalletBalance/DepositAccounts/DepositAccountsTab';

const DepositAccountsPage: FC = memo(() => {
	return (
		<>
			<div className="title-block-wrap">
				{/* <div className="title-block">
					<p className="title">Deposit Accounts</p>
				</div> */}
			</div>
			<DepositAccountsTab />
		</>
	);
});

export default DepositAccountsPage;
