import React, { FC } from 'react';
import Popup from 'reactjs-popup';
import { ROUTES } from 'routes/constants';
import IconSvg from 'ui/Svg/IconSvg';
import { IPopUp } from '../types';

const SuccessUpdateTwoFaPopUp: FC<IPopUp> = ({ open, closeModal }) => {
	const onClose = () => {
		closeModal();
		window.location.replace(ROUTES.login);
	};

	return (
		<Popup open={open} closeOnDocumentClick onClose={onClose}>
			<div className="popup">
				{/* eslint-disable-next-line jsx-a11y/control-has-associated-label,react/button-has-type */}
				<button className="popup__close-btn" onClick={onClose} />
				<div className="popup-header popup-header--mb-20">
					<p className="popup-header__title">Successful</p>
				</div>
				<div className="popup-icon popup-icon--type2">
					<IconSvg name="success-popup" />
				</div>
				<div className="popup-text">
					<p>Your 2FA code has been successfully reset.</p>
				</div>
				<div className="popup-footer">
					<div className="popup-submit">
						<button type="button" className="btn btn-primary btn--full" onClick={onClose}>
							Continue
						</button>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default SuccessUpdateTwoFaPopUp;
